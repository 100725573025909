import takenoko from "./takenoko.jpg";
import grass from "./grass.png";
import github from "./github.jpg";
import qiita from "./qiita.png"
import androbo from "./androbo.png";
import icon from "./icon.png";

export default {
    takenoko: takenoko,
    grass: grass,
    github: github,
    qiita: qiita,
    androbo: androbo,
    icon: icon,
}